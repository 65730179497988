// *** INIT *** //
// set target element(s) to checked or unchecked with data attribute data-checkbox-target
// <input type="checkbox" data-checkbox-id="government-announcement" />

// and reference(s) with data attribute data-checkbox-id
// <div data-checkbox-target="government-announcement"></div>

// optionally an other behavior can be achieved, where the target isn't simply toggled but set to a specific state.
// this is done with the data attribute data-checkbox-should-be-checked.
// <option data-checkbox-target="government-announcement" data-checkbox-should-be-checked="true">Yes</option>

function shouldBeToggled(element) {
  const shouldBeChecked = element.data('checkbox-should-be-checked');
  if (shouldBeChecked == null) return true;
  return false;
}

function toggleCheckbox(checkbox) {
  const isChecked = checkbox.prop('checked');
  checkbox.prop('checked', !isChecked);
}

$(document)
  .on('change', '[data-checkbox-target], .js-toggle-checkbox', (e) => {
    const selectedOption = $(e.target).find('option:selected');
    const checkboxTarget = selectedOption.data('checkbox-target');
    const checkbox = $(`input[type="checkbox"][data-checkbox-id="${checkboxTarget}"]`);

    if (shouldBeToggled(selectedOption)) {
      toggleCheckbox(checkbox);
    } else {
      const checkboxShouldBeChecked = selectedOption.data('checkbox-should-be-checked') === true;
      checkbox.prop('checked', checkboxShouldBeChecked);
    }
  });
