/* global workerFullStop */
import '@crossiety/design/assets/stylesheets/application_migration_period.css';

import 'src/critical';

// Normal
import Turbolinks from 'turbolinks';
import I18n from 'i18n';
import 'src/patches/turbolinks_render_error_pages';

// Vendor libraries
import 'src/vendor/cocoon';
import 'iframe-resizer/js/iframeResizer.contentWindow';

// We need to export pusher before importing web_components which could be also in initializers
import 'src/newpusher';

// CARDS MIGRATION Temporary before initializers so everything would work
import 'src/cards';

// Initializers
import 'src/initializers';

// Web components
import 'src/web_components/application_element/element';
import '@crossiety/design';
import 'src/web_components/attachment/element';

// Test mode helper
import 'src/test_mode';

// Application
import 'src/file_upload';
import 'src/lib/turbolinks_remote_form';

import 'bootstrap-sass/assets/javascripts/bootstrap';

import Snack from '@effectivastudio/snackbar';
import 'src/viewport_height';
import 'src/live_data';
import 'src/online_users';
import 'src/auto-size-textarea';
import 'src/new_image_zoomer';
import 'src/load_more';
import 'src/filter_form';
import 'src/main_nav_auto_hide';
import 'src/proxy_menu';
import 'src/social_buttons';
import 'src/invitations';
import 'src/phones';
import 'src/profile';
import 'src/group_members';
import 'src/notifications';
import 'src/comments';
import 'src/groups';

import 'src/banners';
import 'src/form_modal';
import 'src/rich_text_editor';
import 'src/quick_action_menu';
import 'src/smart_date_picker';
import 'src/toggle_visibility';
import 'src/toggle_checkbox';
import 'src/unique_option';
import 'src/slider';
import 'src/new_mobile_demo';
import 'src/update_app_modal';
import 'src/new_chat';
import 'src/network_notice_setup';
import 'src/download_file';

// Mobile + Desktop
import 'src/new_common_interface';

import 'src/turbolinks_visit_destination';

// App utility functions ************************************************
window.App ||= {};
window.App.utils ||= {};

// Common content *******************************************************

App.promises || (App.promises = {});

App.promises.turbolinks_load = new Promise(((resolve) => {
  document.addEventListener('turbolinks:load', (e) => {
    resolve(e);
  });
}));

App.promises.window_load = new Promise(((resolve) => {
  window.addEventListener('load', (e) => {
    resolve(e);
  });
}));

App.promises.document_load = new Promise(((resolve) => {
  document.addEventListener('DOMContentLoaded', (e) => {
    resolve(e);
  });
}));

// Application content  ************************************************************************+

document.addEventListener('turbolinks:before-cache', () => {
  $('[data-edit]').trigger('cleanup.turbolinks');
  $.fn.select2 && $('.js-select2.select2-hidden-accessible').select2('destroy');
  $('.proxy-menu').remove();
  $('[data-toggle="dropdown"][aria-expanded="true"]').dropdown('toggle');
  $('.snackbar-container').remove();
  App.dropDownMenuOpen = false;
});

$(document).on('submit', '.js-turboform', function onSubmitJsTurboform(e) {
  e.preventDefault();
  Turbolinks.visit(this.action + (this.action.indexOf('?') === -1 ? '?' : '&') + $(this).serialize());
});

$(document).on('change', '.js-change-submit', function onChangeJsChangeSubmit() {
  if (this.dataset.remote) {
    $('[type="submit"]', this).click();
  } else {
    $(this).trigger('submit');
  }
});

/** * DISABLE IOS 10+ ZOOM ** */
document.addEventListener('gesturestart', (e) => {
  e.preventDefault();
});

$(document)
  .on('click', '.js-collapse-select .radio label', function onClickCollapseSelect() {
    const $this = $(this);
    const parentID = $this.closest('.collapse').attr('id');

    $(`[aria-controls="${parentID}"]`).text($this.text());
    $(`#${parentID}`).collapse('hide');
  });

document.addEventListener('turbolinks:load', () => {
  $('.js-event-cards-count .badge').hide();
  $('.js-community-cards-count .badge').hide();
  $('.js-marketplace-cards-count .badge').hide();
});

// Add body padding for pages where we have floating-btn in bottom right corner.
document.addEventListener('turbolinks:load', () => {
  const el = $('.js-floating-btn');
  el.length > 0 && $('body').addClass('has-floating-btn');
});

// Onboarding steps
$(document)
  .on('updateGroupDescription:done updatePhone:done', '.step', (e) => {
    $(e.currentTarget).trigger('step:completed');
  })
  .on('updateGroupDescription:fail updatePhone:fail', '.step', (e) => {
    $(e.currentTarget).trigger('step:uncompleted');
  })
  .on('ajax:send', '.js-notification-update', (e) => {
    $(e.currentTarget).trigger('step:completed');
  })
  .on('ajax:error', '.js-notification-update', (e) => {
    $(e.currentTarget).trigger('step:uncompleted');
  })
  .on('step:completed', '.step', (e) => {
    $(e.currentTarget).addClass('is-completed');
  })
  .on('step:uncompleted', '.step', (e) => {
    $(e.currentTarget).removeClass('is-completed');
  });

$(document).on('show.bs.dropdown hidden.bs.dropdown', (e) => {
  document.body.classList[e.type === 'show' ? 'add' : 'remove']('user-dropdown-open');
});

$(document).on('show.bs.dropdown hidden.bs.dropdown', (e) => {
  App.dropDownMenuOpen = e.type === 'show';
});

$(document)
  .on('ajax:success', '.js-logout', () => {
    workerFullStop();
    Turbolinks.visit('/login', { action: 'replace' });
  });

$(document).on('ajax:success', '.js-snack-saved', () => {
  Snack({ text: I18n.t('snack.saved') });
});

$(document).on('ajax:success', '.js-sent-activation-instructions', () => {
  Snack({ text: I18n.t('snack.sent_activation_instructions') });
});

$(document).on('ajax:success', '.js-page-participate', () => {
  Turbolinks.visit(window.location.href, { action: 'replace' });
});

// Other -------------------------------------------
$(document)
  .on('click', '.js-focus-related', (e) => {
    $(`[data-target="${e.currentTarget.id}"]`).focus();
  })
  .on('click', '.js-select-related', (e) => {
    $(`#${e.currentTarget.dataset.target}`).prop('checked', true);
  })
  .on('change', '.js-currency-select', (e) => {
    const $el = $(e.target);
    const c = $el.find(':selected').data('currency');

    if (!c) { return; }

    const $inputWrapper = $('.control-wrapper[data-currency]', $el.closest('form'));

    $inputWrapper[0].className = `control-wrapper ${c.toLowerCase()}`;
  });

document.addEventListener('turbolinks:load', () => {
  // Add body class if non-touch device
  // eslint-disable-next-line
  const touchSupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
  !touchSupport && $('html').addClass('non-touch');
});

// Set data-page attribute with controller-action variables
document.addEventListener('turbolinks:before-render', ({ data }) => {
  const newBody = data && data.newBody;
  if (!newBody) { return; }

  document.documentElement.setAttribute('data-page', newBody.dataset.htmlPage);
});

document.addEventListener('modal-content-updated', (e) => {
  const { modal } = e.target;
  const form = modal.querySelector('form');

  $(form).enableClientSideValidations();
  $(form).find('.nested-fields').parent().trigger('cocoon:after-insert');
});

document.addEventListener('remote:error', (e) => {
  Snack({ text: e.detail.error, customClass: 'snackbar-danger' });
});
