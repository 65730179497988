// TODO: enableClientSideValidations are not working properly

// CARD MIGRATION: New code
document.addEventListener('fragmentInclude:replaced', (e) => {
  e.target
    .querySelectorAll('xs-comment:not([repliesCount="0"])')
    .forEach(el => el.open = true);

  $(e.target).find('form').enableClientSideValidations();
});
