import $ from 'jquery';

$(document)
  .on('change', 'input[type="file"]', function onChangeFileUpload() {
    const file = this.files[0];
    const $this = $(this);
    const $fileUploadWrapper = $this.closest('.file-upload-wrapper');

    // IE11 triggers change on set and clear so we need return if no file
    if (!file) { return; }

    $fileUploadWrapper.find('.file-preview').hide();
    $this.siblings('.js-file-name').html(file.name);
    $this.siblings('.js-clear-file').show();
    $fileUploadWrapper.addClass('file-attached');
    $fileUploadWrapper.find('.js-should-delete-file').val('0');
    $this.trigger('elementresize');
  })

  // RESET FILE INPUT
  .on('click', '.js-clear-file', function onClickFileUpload() {
    const $this = $(this);
    const $fileUploadWrapper = $this.closest('.file-upload-wrapper');

    $this.siblings('input[type="file"]').val('').trigger('elementresize');
    $this.siblings('.js-file-name').html('');
    $fileUploadWrapper.removeClass('file-attached');
    $this.hide();
    $fileUploadWrapper.find('.file-preview').show();
    $fileUploadWrapper.find('.js-should-delete-file').val('0');
  })

  .on('reset', 'form', function onResetFileUpload() {
    $('.js-clear-file', this).click();
  })

  // SHOULD DELETE FILE
  .on('click', '.js-delete-file-btn', function onClickFileUploadDelete(e) {
    e.preventDefault();

    const $this = $(this);
    const $fileUploadWrapper = $this.closest('.file-upload-wrapper');

    $fileUploadWrapper.find('.js-should-delete-file').val('1');
    $this.closest('.file-preview').remove();
  });
