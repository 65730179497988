export const isItemInViewPort = (el) => {
  if (!$(el).length) { return false; }

  const scroll = window.scrollY || window.pageYOffset; // eslint-disable-line compat/compat
  const boundsTop = el[0].getBoundingClientRect().top + scroll;

  const viewport = {
    top: scroll,
    bottom: scroll + window.innerHeight,
  };

  const bounds = {
    top: boundsTop,
    bottom: boundsTop + el.clientHeight,
  };

  return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom)
    || (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
};

export const scrollDirection = {
  previousScrollPosition: 0,

  getCurrentPosition() {
    return window.pageYOffset || document.documentElement.scrollTop;
  },
  setInitialScrollPosition() {
    this.previousScrollPosition = this.getCurrentPosition();
  },
  getScrollDirection() {
    const st = this.getCurrentPosition();
    const scrollDirectionValue = st > this.previousScrollPosition ? 'down' : 'up';
    this.previousScrollPosition = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    return scrollDirectionValue;
  },
};

export const getIOSVersion = () => {
  if (/iP(hone|od|ad)/.test(navigator.userAgent)) {
    const v = (navigator.userAgent).match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
  return false;
};

// Delegate function helper
export function on(rootEl, eventName, selector, fn) {
  const element = rootEl.querySelector ? rootEl : document.querySelector(rootEl);

  // eslint-disable-next-line consistent-return
  element.addEventListener(eventName, (event) => {
    const possibleTargets = element.querySelectorAll(selector);
    const { target } = event;

    for (let i = 0, l = possibleTargets.length; i < l; i++) {
      let el = target;
      const p = possibleTargets[i];

      while (el && el !== element) {
        if (el === p) {
          return fn(p, event);
        }

        el = el.parentNode;
      }
    }
  });
}
